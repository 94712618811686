<template>
  <div class="AdminUserOptions" v-if="$isAdmin">
    <div class="AdminUserOptionsUser" dpadding>
      <UserAvatar :user="user" size="82px" />
      <Spacer num=".7" />
      <p class="AdminUserOptionsName">{{ user.name }}</p>
      <p class="AdminUserOptionsEmail">{{ user.email }}</p>
    </div>
    <Spacer num="1" />
    <div class="AdminUserOptionsMenu">
      <LargeButton
        v-if="isCreator(user)"
        icon="mf_lock"
        :label="$locale['appearance_on_landing']"
        :desc="$locale['appearance_on_landing_desc']"
        @click="
          modal.close(() => {
            Modal({
              user: user,
              title: user.name,
              class: 'LandingPageCreatorEdit default fullMobile',
              component: () => import('./AdminCreatorLanding.vue'),
              animation: 'fadeIn',
            });
          })
        "
      />

      <LargeButton
        v-if="isCreator(user)"
        icon="user"
        :label="$locale['user_profile']"
        :desc="$locale['user_profile_desc']"
        @click="
          modal.close(() => {
            Modal({
              user: user,
              title: user.name,
              class: 'LandingPageCreatorEdit default fullMobile',
              component: () => import('./AdminCreatorProfile.vue'),
              animation: 'fadeIn',
            });
          })
        "
      />

      <LargeButton :label="$locale['delete_user']" icon="trash" @click="deleteUserConfirm" dcolor />
    </div>
    <Spacer v-if="$isMobile" num="4" />
  </div>
</template>

<script>
export default {
  props: ["user", "modal"],
  methods: {
    deleteUserConfirm: function() {
      this.view({
        user: this.user,
        title: this.$locale["delete_user"],
        closeOutside: true,
        component: () => import("./AdminUserDelete.vue"),
        onDelete: this.modal.close,
      });
    },
  },
};
</script>

<style lang="scss">
.AdminUserOptions {
  .AdminUserOptionsUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .AdminUserOptionsName {
    font-size: 120%;
  }
}
</style>
