var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isAdmin)?_c('div',{staticClass:"AdminUserOptions"},[_c('div',{staticClass:"AdminUserOptionsUser",attrs:{"dpadding":""}},[_c('UserAvatar',{attrs:{"user":_vm.user,"size":"82px"}}),_c('Spacer',{attrs:{"num":".7"}}),_c('p',{staticClass:"AdminUserOptionsName"},[_vm._v(_vm._s(_vm.user.name))]),_c('p',{staticClass:"AdminUserOptionsEmail"},[_vm._v(_vm._s(_vm.user.email))])],1),_c('Spacer',{attrs:{"num":"1"}}),_c('div',{staticClass:"AdminUserOptionsMenu"},[(_vm.isCreator(_vm.user))?_c('LargeButton',{attrs:{"icon":"mf_lock","label":_vm.$locale['appearance_on_landing'],"desc":_vm.$locale['appearance_on_landing_desc']},on:{"click":function($event){_vm.modal.close(function () {
          _vm.Modal({
            user: _vm.user,
            title: _vm.user.name,
            class: 'LandingPageCreatorEdit default fullMobile',
            component: function () { return import('./AdminCreatorLanding.vue'); },
            animation: 'fadeIn',
          });
        })}}}):_vm._e(),(_vm.isCreator(_vm.user))?_c('LargeButton',{attrs:{"icon":"user","label":_vm.$locale['user_profile'],"desc":_vm.$locale['user_profile_desc']},on:{"click":function($event){_vm.modal.close(function () {
          _vm.Modal({
            user: _vm.user,
            title: _vm.user.name,
            class: 'LandingPageCreatorEdit default fullMobile',
            component: function () { return import('./AdminCreatorProfile.vue'); },
            animation: 'fadeIn',
          });
        })}}}):_vm._e(),_c('LargeButton',{attrs:{"label":_vm.$locale['delete_user'],"icon":"trash","dcolor":""},on:{"click":_vm.deleteUserConfirm}})],1),(_vm.$isMobile)?_c('Spacer',{attrs:{"num":"4"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }